import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from 'app/auth/components/login/login.component';
import { TwoFactorCodeComponent } from './components/two-factor-code/two-factor-code.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '2fa',
    component: TwoFactorCodeComponent,
  },
];

/* @export
 * @class AuthRoutingModule
 */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutes {}
