<div id="login" fxLayout="column">
  <div id="login-form-wrapper" fxLayout="row" fxLayoutAlign="space-around">
    <div class="splash">
    </div>
    <div id="login-form" [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }">
      <div class="logo">
        <img src="assets/images/logos/icon.png" alt="logo" />
      </div>

      <!-- <div class="title">LOGIN TO YOUR ACCOUNT</div> -->

      <form name="loginForm" [formGroup]="loginForm" novalidate>
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" />
          <mat-icon matSuffix class="secondary-text">mail</mat-icon>
          <mat-error *ngIf="loginForm.get('email').hasError('required')">
            Email is required
          </mat-error>
          <mat-error *ngIf="
              !loginForm.get('email').hasError('required') &&
              loginForm.get('email').hasError('email')
            ">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>
  
        <app-password-input [control]="loginForm.controls['password']" [label]="'Password'"></app-password-input>

        <button mat-button (click)="onLogin()" class="submit-button pulse-animation" aria-label="Sign In"
          [disabled]="loginForm.invalid || processLogin" [ngClass]="{'button-in-progress': processLogin}">
          {{ processLogin ? 'Signing In...' : 'Sign In' }}
        </button>
      </form>

      <div class="login-error">
        <span *ngIf="!!errorMessage" class="error-text">{{errorMessage}}</span>
      </div>

    </div>
  </div>
</div>
