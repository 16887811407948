import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthorizeService } from 'app/auth/authorize.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationPaths, ReturnUrlType } from 'app/core/app.constants';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AppBusService } from 'app/core/services/app-bus.service';
import { ServiceIsUnavailableService } from '@common/shared/services/service-is-unavailable.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  errorMessage: string = null;
  processLogin: boolean = false;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private fuseProgressBar: FuseProgressBarService,
    private _formBuilder: FormBuilder,
    private authService: AuthorizeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appBusService: AppBusService,
    private serviceIsUnavailableService: ServiceIsUnavailableService,
  ) {
    // Configure the layout
    this._fuseConfigService.hideFuseLayout();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  /**
   * On Login
   */
  async onLogin(): Promise<void> {
    const loginFields = this.loginForm.getRawValue();
    const returnUrl = this.getReturnUrl();
    this.fuseProgressBar.show();
    this.processLogin = true;
    try {
      this.serviceIsUnavailableService.displayMessage = true;
      const successfullyLoggedIn = await this.authService.authenticateUser(
        loginFields.email,
        loginFields.password,
      );
      console.debug('logged in status: ', successfullyLoggedIn);
      if (successfullyLoggedIn) {
        this.appBusService.login();
        await this.navigateToReturnUrl(returnUrl);
      }
    } finally {
      this.processLogin = false;
      this.fuseProgressBar.hide();
    }
  }
  private navigateToReturnUrl(returnUrl: string): Promise<boolean> {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.
    return this.router.navigateByUrl(returnUrl, {
      replaceUrl: true,
    });
  }

  private getReturnUrl(state?: INavigationState): string {
    const fromQuery = (
      this.activatedRoute.snapshot.queryParams as INavigationState
    ).returnUrl;
    // If the url is comming from the query string, check that is either
    // a relative url or an absolute url
    if (
      fromQuery &&
      !(
        fromQuery.startsWith(`${window.location.origin}/`) ||
        // eslint-disable-next-line no-useless-escape
        /\/[^\/].*/.test(fromQuery)
      )
    ) {
      // This is an extra check to prevent open redirects.
      throw new Error(
        'Invalid return url. The return url needs to have the same origin as the current page.',
      );
    }
    return (
      (state && state.returnUrl) ||
      fromQuery ||
      ApplicationPaths.DefaultLoginRedirectPath
    );
  }
}

interface INavigationState {
  [ReturnUrlType]: string;
}
