import { FuseNavigation } from '@fuse/types';
import { IRouteInfo } from '@common/shared/models/route-info';
import { PermissionToken } from '@common/shared/models/permission-token';
import {
  PermissionsInfo,
  PermissionsInfoMode,
} from '@common/shared/models/permission-info';

export interface IPayloadItem extends IRouteInfo {
  breadcrumb?: string;
  canSwitchContextLevel?: boolean;
  allowCrossTenantAccess?: boolean;
  skipChildrenTracking?: boolean;
  menuEnablerSettingName?: string;
}

export interface INavigationItem {
  id: string;
  title: string;
  type: 'item' | 'group' | 'collapsable';
  url: string;
  hidden?: boolean;
  classes?: string;
  isComing?: boolean;
  isActionable?: boolean;
  payload?: IPayloadItem;
  children?: INavigationItem[];
  exactMatch?: boolean;
  permissions?: PermissionsInfo;
  getUrl?(id: string): string;
}

export interface INavigationConfig {
  root: INavigationItem;
  dashboards: INavigationItem;
  assessments: INavigationItem;
  assessmentsDetail: INavigationItem;
  personalRecords: INavigationItem;
  trainings: INavigationItem;
  editAssessment: INavigationItem;
  assessmentSummary: INavigationItem;
  permissions: INavigationItem;
  settings: INavigationItem;
  notAuthorized: INavigationItem;
  sign_out: INavigationItem;
  in_take: INavigationItem;
}

export interface INavigationTree {
  root: INavigationItem;
  dashboards: INavigationItem;
  assessments: {
    assessments: INavigationItem;
    allAssessments: INavigationItem;
    assessmentsByPerson: INavigationItem;
    assessmentsByDate: INavigationItem;
    addAssessment: INavigationItem;
    editAssessment: INavigationItem;
    assessmentSummary: INavigationItem;
    assessmentsDetail: INavigationItem;
    assessmentsOverview: INavigationItem;
    assessmentNormativeDetails: INavigationItem;
    providerAssignment: INavigationItem;
    addIndividualAssessment: INavigationItem;
    personalRecordAssessment: INavigationItem;
  };
  personalRecords: {
    personalRecords: INavigationItem;
    personalRecordsList: INavigationItem;
    addPersonalRecord: INavigationItem;
    editPerson: INavigationItem;
    personalGeneral: INavigationItem;
    personalMedicalCoverage: INavigationItem;
    personalAssessments: INavigationItem;
    personalTrainingHistory: INavigationItem;
    personalInStudioProgram: INavigationItem;
    personalOutOfStudioProgram: INavigationItem;
    personalTraining: INavigationItem;
    insurance: INavigationItem;
  };
  trainings: {
    trainings: INavigationItem;
    trainingProgress: INavigationItem;
    trainingSetHistory: INavigationItem;
    inStudio: INavigationItem;
    outOfStudio: INavigationItem;
    locationSchedule: INavigationItem;
    inStudioDailyAttendance: INavigationItem;
    instructors: INavigationItem;
    addInstructor: INavigationItem;
    editInstructor: INavigationItem;
    exerciseProgramList: INavigationItem;
    addProgram: INavigationItem;
    editProgram: INavigationItem;
    programAssignment: INavigationItem;
    programOverview: INavigationItem;
    individualEdit: INavigationItem;
    newIndividual: INavigationItem;
  };
  permissions: {
    permissions: INavigationItem;
    roles: INavigationItem;
    users: INavigationItem;
    addUser: INavigationItem;
    editUser: INavigationItem;
    preferences: INavigationItem;
    ipRestrictions: INavigationItem;
  };
  settings: {
    settings: INavigationItem;
    medicalProviders: INavigationItem;
    healthcareProviders: INavigationItem;
    addProvider: INavigationItem;
    editProvider: INavigationItem;
    training: INavigationItem;
    exerciseList: INavigationItem;
    addExercise: INavigationItem;
    editExercise: INavigationItem;
  };
  errors: {
    serverIsUnavailable: INavigationItem;
    notAuthorized: INavigationItem;
    ipRestriction: INavigationItem;
    permissionsRestriction: INavigationItem;
  };
  sign_out: INavigationItem;
  in_take: INavigationItem;
  login: INavigationItem;
  twoFactorCode: INavigationItem;
}

export const NavigationTree: INavigationTree = {
  root: {
    id: 'root',
    title: 'Root',
    type: 'item',
    url: '',
    hidden: true,
  },
  dashboards: {
    id: 'dashboards',
    title: 'Dashboards',
    type: 'item',
    url: '/dashboards',
    payload: {
      breadcrumb: 'Dashboards',
    },
  },
  assessments: {
    assessments: {
      id: 'assessments',
      title: 'Assessments',
      type: 'collapsable',
      url: '',
      payload: {
        breadcrumb: 'Assessments',
      },
    },
    allAssessments: {
      id: 'assessments-all',
      title: 'All',
      type: 'item',
      url: '/assessments',
      payload: {
        breadcrumb: 'All Assessments',
        allowCrossTenantAccess: true,
      },
      exactMatch: true,
    },
    assessmentsByPerson: {
      id: 'assessments-by-person',
      title: 'By Person',
      type: 'item',
      url: '/assessments/by-person',
      payload: {
        breadcrumb: 'Assessments By Person',
      },
    },
    assessmentsByDate: {
      id: 'assessments-by-date',
      title: 'By Date',
      type: 'item',
      url: '/assessments/by-date',
      payload: {
        breadcrumb: 'Assessments By Date',
      },
    },
    addAssessment: {
      id: 'add-assessment',
      title: 'Add',
      type: 'item',
      url: '/assessments/add',
      payload: {
        breadcrumb: 'Add assessment',
        canSwitchContextLevel: false,
      },
      permissions: new PermissionsInfo(
        [PermissionToken.AdminEditAccess],
        PermissionsInfoMode.Any,
      ),
    },
    editAssessment: {
      id: 'edit-assessment',
      title: 'Edit assessment',
      type: 'item',
      url: '/assessments/{id}',
      payload: {
        breadcrumb: 'Edit assessment',
        canSwitchContextLevel: false,
      },
      getUrl(assessmentId: string): string {
        if (!assessmentId) {
          throw Error('assessmentId is null or undefined');
        }
        return NavigationTree.assessments.editAssessment.url.replace(
          '{id}',
          assessmentId,
        );
      },
    },
    assessmentSummary: {
      id: 'assessment-summary',
      title: 'Assessment Summary',
      type: 'item',
      url: '/assessments/{id}/summary',
      payload: {
        breadcrumb: 'Assessment Summary',
        canSwitchContextLevel: false,
      },
      getUrl(assessmentId: string): string {
        if (!assessmentId) {
          throw Error('assessmentId is null or undefined');
        }
        return NavigationTree.assessments.assessmentSummary.url.replace(
          '{id}',
          assessmentId,
        );
      },
    },
    assessmentsDetail: {
      id: 'assessmentsDetail',
      title: 'Assessment Detail',
      type: 'item',
      url: '/assessments/{id}/details',
      payload: {
        breadcrumb: 'Assessment Details',
        canSwitchContextLevel: false,
      },
      getUrl(assessmentId: string): string {
        if (!assessmentId) {
          throw Error('assessmentId is null or undefined');
        }
        return NavigationTree.assessments.assessmentsDetail.url.replace(
          '{id}',
          assessmentId,
        );
      },
      hidden: true,
    },
    assessmentsOverview: {
      id: 'assessmentsOverview',
      title: 'Assessment Overview',
      type: 'item',
      url: '/assessments/{id}/results',
      payload: {
        breadcrumb: 'Assessment Overview',
        canSwitchContextLevel: false,
      },
      getUrl(assessmentId: string): string {
        if (!assessmentId) {
          throw Error('assessmentId is null or undefined');
        }
        return NavigationTree.assessments.assessmentsOverview.url.replace(
          '{id}',
          assessmentId,
        );
      },
      hidden: true,
    },
    assessmentNormativeDetails: {
      id: 'assessment-normative-details',
      title: 'Assessment Normative Details',
      type: 'item',
      url: '/assessments/normatives',
      payload: {
        breadcrumb: 'Assessment Normative Details',
      },
    },
    providerAssignment: {
      id: 'provider-assignment',
      title: 'Provider Assignment',
      type: 'item',
      url: '/assessment-results/{id}/assign-provider',
      payload: {
        breadcrumb: 'Provider Assignment',
        canSwitchContextLevel: false,
      },
      getUrl(resultId: string): string {
        if (!resultId) {
          throw Error('resultId is null or undefined');
        }
        return NavigationTree.assessments.providerAssignment.url.replace(
          '{id}',
          resultId,
        );
      },
    },
    personalRecordAssessment: {
      id: 'personal-record-assessment',
      title: 'Personal Record Assessment',
      type: 'item',
      url: '/personal-record-assessments/{id}',
      payload: {
        breadcrumb: '<% name %>, <% date %>, <% description %>',
        canSwitchContextLevel: false,
      },
      getUrl(id: string): string {
        if (!id) {
          throw Error('resultId is null or undefined');
        }
        return NavigationTree.assessments.personalRecordAssessment.url.replace(
          '{id}',
          id,
        );
      },
    },
    addIndividualAssessment: {
      id: 'add-individual-assessment',
      title: 'Add Individual Assessment',
      type: 'item',
      url: 'personal-record-assessments/add',
      payload: {
        breadcrumb: '<% name %>',
        canSwitchContextLevel: false,
      },
    },
  },
  personalRecords: {
    personalRecords: {
      id: 'personal-records',
      title: 'Personal Records',
      type: 'collapsable',
      url: '',
      payload: {
        breadcrumb: 'Personal Records',
      },
    },
    personalRecordsList: {
      id: 'personal-records-list',
      title: 'List',
      type: 'item',
      url: '/personal-records',
      payload: {
        breadcrumb: 'Personal Records List',
      },
      exactMatch: true,
    },
    addPersonalRecord: {
      id: 'add-personal-record',
      title: 'Add',
      type: 'item',
      url: '/personal-records/add',
      payload: {
        breadcrumb: 'Add Personal Record',
        canSwitchContextLevel: false,
      },
      exactMatch: true,
      permissions: new PermissionsInfo(
        [PermissionToken.AdminEditAccess],
        PermissionsInfoMode.Any,
      ),
    },
    editPerson: {
      id: 'edit-person',
      title: 'Edit Person',
      type: 'item',
      url: '/personal-records/{id}',
      payload: {
        canSwitchContextLevel: false,
        breadcrumb: 'Edit Person - <% name %>',
        skipChildrenTracking: true,
      },
      hidden: true,
      getUrl(personalRecordId: string): string {
        if (!personalRecordId) {
          throw Error('personalRecordId is null or undefined');
        }
        return NavigationTree.personalRecords.editPerson.url.replace(
          '{id}',
          personalRecordId,
        );
      },
    },
    personalGeneral: {
      id: 'personal-general',
      title: 'Personal General',
      type: 'item',
      url: '/personal-records/{id}/general',
      getUrl(personalRecordId: string): string {
        if (!personalRecordId) {
          throw Error('personalRecordId is null or undefined');
        }
        return NavigationTree.personalRecords.personalGeneral.url.replace(
          '{id}',
          personalRecordId,
        );
      },
    },
    personalMedicalCoverage: {
      id: 'personal-medical-coverage',
      title: 'Personal Medical Coverage',
      type: 'item',
      url: '/personal-records/{id}/medical-coverage',
      getUrl(personalRecordId: string): string {
        if (!personalRecordId) {
          throw Error('personalRecordId is null or undefined');
        }
        return NavigationTree.personalRecords.personalMedicalCoverage.url.replace(
          '{id}',
          personalRecordId,
        );
      },
    },
    personalTraining: {
      id: 'personal-training',
      title: 'Personal Training',
      type: 'item',
      url: '/personal-records/{id}/training',
      getUrl(personalRecordId: string): string {
        if (!personalRecordId) {
          throw Error('personalRecordId is null or undefined');
        }
        return NavigationTree.personalRecords.personalTraining.url.replace(
          '{id}',
          personalRecordId,
        );
      },
    },
    personalAssessments: {
      id: 'personal-assessments',
      title: 'Personal Assessments',
      type: 'item',
      url: '/personal-records/{id}/assessments',
      getUrl(personalRecordId: string): string {
        if (!personalRecordId) {
          throw Error('personalRecordId is null or undefined');
        }
        return NavigationTree.personalRecords.personalAssessments.url.replace(
          '{id}',
          personalRecordId,
        );
      },
    },
    personalTrainingHistory: {
      id: 'personal-training-history',
      title: 'Personal Training History',
      type: 'item',
      url: '/personal-records/{id}/training-history',
      getUrl(personalRecordId: string): string {
        if (!personalRecordId) {
          throw Error('personalRecordId is null or undefined');
        }
        return NavigationTree.personalRecords.personalTrainingHistory.url.replace(
          '{id}',
          personalRecordId,
        );
      },
    },
    personalInStudioProgram: {
      id: 'personal-in-studio-program',
      title: 'Personal In-Studio Program',
      type: 'item',
      url: '/personal-records/{id}/in-studio-program',
      getUrl(personalRecordId: string): string {
        if (!personalRecordId) {
          throw Error('personalRecordId is null or undefined');
        }
        return NavigationTree.personalRecords.personalInStudioProgram.url.replace(
          '{id}',
          personalRecordId,
        );
      },
    },
    personalOutOfStudioProgram: {
      id: 'personal-out-of-studio-program',
      title: 'Personal Out Of Studio Program',
      type: 'item',
      url: '/personal-records/{id}/out-of-studio-program',
      getUrl(personalRecordId: string): string {
        if (!personalRecordId) {
          throw Error('personalRecordId is null or undefined');
        }
        return NavigationTree.personalRecords.personalOutOfStudioProgram.url.replace(
          '{id}',
          personalRecordId,
        );
      },
    },
    insurance: {
      id: 'insurance',
      title: 'Insurance',
      type: 'item',
      url: '/personal-records/insurance',
      payload: {
        breadcrumb: 'Personal Records Insurance',
      },
    },
  },
  trainings: {
    trainings: {
      id: 'trainings',
      title: 'Training',
      type: 'collapsable',
      url: '',
      payload: {
        breadcrumb: 'Training',
      },
    },
    trainingProgress: {
      id: 'training-progress',
      title: 'Status',
      type: 'item',
      url: '/out-of-studio-trainings',
      payload: {
        breadcrumb: 'Training Status',
      },
      exactMatch: true,
    },
    trainingSetHistory: {
      id: 'training-set-history',
      title: 'Training Details',
      type: 'item',
      url: '/out-of-studio-trainings/{id}',
      payload: {
        breadcrumb: 'Training Details',
        canSwitchContextLevel: false,
      },
      getUrl(id: string): string {
        if (!id) {
          throw Error('id is null or undefined');
        }
        return NavigationTree.trainings.trainingSetHistory.url.replace(
          '{id}',
          id,
        );
      },
      exactMatch: true,
      hidden: true,
    },
    inStudio: {
      id: 'in-studio',
      title: 'In Studio',
      type: 'collapsable',
      url: null,
      payload: {
        breadcrumb: 'In Studio',
      },
    },
    locationSchedule: {
      id: 'location-schedule',
      title: 'Location Schedule',
      type: 'item',
      url: '/in-studio-trainings/location-schedule',
      payload: {
        breadcrumb: 'Location Schedule',
      },
      exactMatch: true,
    },
    inStudioDailyAttendance: {
      id: 'in-studio-daily-attendance',
      title: 'In Studio Daily Attendance',
      type: 'item',
      url: '/in-studio-trainings/daily-attendance',
      payload: {
        breadcrumb: 'In Studio Daily Attendance',
      },
      exactMatch: true,
    },
    outOfStudio: {
      id: 'out-of-studio',
      title: 'Out of Studio',
      type: 'collapsable',
      url: null,
      payload: {
        breadcrumb: 'Out of Studio',
      },
    },
    instructors: {
      id: 'instructor-list',
      title: 'Instructors',
      type: 'item',
      url: '/training-instructors',
      payload: {
        breadcrumb: 'Instructors List',
      },
      exactMatch: true,
    },
    addInstructor: {
      id: 'add-instructor',
      title: 'Add Training Instructor',
      type: 'item',
      url: '/training-instructors/add',
      payload: {
        breadcrumb: 'Add Training Instructor',
        canSwitchContextLevel: false,
      },
      hidden: true,
    },
    editInstructor: {
      id: 'edit-instructor',
      title: 'Edit Training Instructor',
      type: 'item',
      url: '/training-instructors/{id}',
      payload: {
        breadcrumb: 'Edit Training Instructor',
        canSwitchContextLevel: false,
      },
      getUrl(instructorId: string): string {
        if (!instructorId) {
          throw Error('instructorId is null or undefined');
        }
        return NavigationTree.trainings.editInstructor.url.replace(
          '{id}',
          instructorId,
        );
      },
      hidden: true,
    },
    exerciseProgramList: {
      id: 'exercise-program-list',
      title: 'Exercise Program',
      type: 'item',
      url: '/exercise-programs',
      payload: {
        breadcrumb: 'Exercise Program List',
      },
    },
    addProgram: {
      id: 'add-program',
      title: 'Add Program',
      type: 'item',
      url: '/exercise-programs/add',
      payload: {
        breadcrumb: 'Exercise Program',
        canSwitchContextLevel: false,
      },
      hidden: true,
    },
    editProgram: {
      id: 'edit-program',
      title: 'Edit Program',
      type: 'item',
      url: '/exercise-programs/{id}',
      payload: {
        breadcrumb: 'Exercise Program',
        canSwitchContextLevel: false,
      },
      hidden: true,
      getUrl(programId: string): string {
        if (!programId) {
          throw Error('personalRecordId is null or undefined');
        }
        return NavigationTree.trainings.editProgram.url.replace(
          '{id}',
          programId,
        );
      },
    },
    programAssignment: {
      id: 'program-assignment',
      title: 'Program Assignment',
      type: 'item',
      url: '/exercise-programs/{id}/assignment',
      payload: {
        breadcrumb: 'Program Assignment',
        canSwitchContextLevel: false,
      },
      hidden: true,
      getUrl(programId: string): string {
        if (!programId) {
          throw Error('personalRecordId is null or undefined');
        }
        return NavigationTree.trainings.programAssignment.url.replace(
          '{id}',
          programId,
        );
      },
    },
    programOverview: {
      id: 'program-overview',
      title: 'Exercise Program',
      type: 'item',
      url: '/exercise-programs/{id}/overview',
      payload: {
        breadcrumb: 'Exercise Program',
        canSwitchContextLevel: false,
      },
      hidden: true,
      getUrl(programId: string): string {
        if (!programId) {
          throw Error('ProgramId is null or undefined');
        }
        return NavigationTree.trainings.programOverview.url.replace(
          '{id}',
          programId,
        );
      },
    },
    individualEdit: {
      id: 'individual-edit',
      title: 'Individual Edit',
      type: 'item',
      url: '/exercise-programs/personal-record-exercise-program/{id}',
      payload: {
        breadcrumb: 'Individual Edit',
        canSwitchContextLevel: false,
      },
      hidden: true,
      getUrl(id: string): string {
        if (!id) {
          throw Error('id is null or undefined');
        }
        return NavigationTree.trainings.individualEdit.url.replace('{id}', id);
      },
    },
    newIndividual: {
      id: 'new-individual',
      title: 'Individual Edit',
      type: 'item',
      url: '/exercise-programs/personal-record-exercise-program/add',
      payload: {
        breadcrumb: 'Individual Edit',
        canSwitchContextLevel: false,
      },
      hidden: true,
    },
  },
  permissions: {
    permissions: {
      id: 'roles',
      title: 'Permissions',
      type: 'collapsable',
      url: '',
      payload: {
        breadcrumb: 'Roles',
      },
    },
    roles: {
      id: 'rolesList',
      title: 'Roles',
      type: 'item',
      url: '/roles',
      payload: {
        breadcrumb: 'Roles',
        allowCrossTenantAccess: true,
      },
      permissions: new PermissionsInfo(
        [PermissionToken.EditRoles],
        PermissionsInfoMode.Any,
      ),
    },
    users: {
      id: 'usersList',
      title: 'Users',
      type: 'item',
      url: '/users',
      payload: {
        breadcrumb: 'Users List',
        allowCrossTenantAccess: true,
      },
      permissions: new PermissionsInfo(
        [PermissionToken.EditRoles],
        PermissionsInfoMode.Any,
      ),
    },
    addUser: {
      id: 'add',
      title: 'Add',
      type: 'item',
      url: '/users/add',
      payload: {
        breadcrumb: 'Add User',
        allowCrossTenantAccess: true,
        canSwitchContextLevel: false,
      },
      exactMatch: true,
      permissions: new PermissionsInfo(
        [PermissionToken.AdminEditAccess, PermissionToken.AdminEditAccess],
        PermissionsInfoMode.Any,
      ),
    },
    editUser: {
      id: 'editUser',
      title: 'Edit User',
      type: 'item',
      hidden: true,
      url: '/users/{id}',
      payload: {
        breadcrumb: 'Edit User <% name %>',
        allowCrossTenantAccess: true,
        canSwitchContextLevel: false,
      },
      permissions: new PermissionsInfo(
        [PermissionToken.EditRoles, PermissionToken.AdminEditAccess],
        PermissionsInfoMode.Any,
      ),
    },
    ipRestrictions: {
      id: 'ip-restrictions',
      title: 'IP Restrictions',
      type: 'item',
      url: '/ip-restrictions',
      payload: {
        breadcrumb: 'IP Restrictions',
        allowCrossTenantAccess: false,
        canSwitchContextLevel: false,
        menuEnablerSettingName: 'ipRestrictionsEnabled',
        componentId: 'ip-restrictions',
      },
      permissions: new PermissionsInfo(
        [PermissionToken.EditRoles, PermissionToken.AdminEditAccess],
        PermissionsInfoMode.All,
      ),
    },
    preferences: {
      id: 'preferences',
      title: 'Preferences',
      type: 'item',
      url: '/preferences',
      payload: {
        breadcrumb: 'Preferences',
        allowCrossTenantAccess: true,
        canSwitchContextLevel: false,
        componentId: 'preferences',
      },
    },
  },
  settings: {
    settings: {
      id: 'settings',
      title: 'Settings',
      type: 'collapsable',
      url: '',
      payload: {
        breadcrumb: 'settings',
      },
    },
    medicalProviders: {
      id: 'medicalProviders',
      title: 'Medical Providers',
      type: 'collapsable',
      url: null,
      payload: {
        breadcrumb: 'Medical Providers',
      },
    },
    healthcareProviders: {
      id: 'healthcare-providers',
      title: 'List',
      type: 'item',
      url: '/medical-providers',
      payload: {
        breadcrumb: 'Healthcare Providers',
      },
      exactMatch: true,
    },
    addProvider: {
      id: 'add-provider',
      title: 'Add Medical Provider',
      type: 'item',
      url: '/medical-providers/add',
      payload: {
        breadcrumb: 'Add Medical Provider',
        canSwitchContextLevel: false,
      },
      hidden: true,
    },
    editProvider: {
      id: 'edit-provider',
      title: 'Edit Medical Provider',
      type: 'item',
      url: '/medical-providers/{id}',
      payload: {
        breadcrumb: 'Edit Medical Provider',
        canSwitchContextLevel: false,
      },
      hidden: true,
    },
    training: {
      id: 'training',
      title: 'Training',
      type: 'collapsable',
      url: null,
      payload: {
        breadcrumb: 'Training',
      },
    },
    exerciseList: {
      id: 'exercise-list',
      title: 'Exercise List',
      type: 'item',
      url: '/training-exercises',
      payload: {
        breadcrumb: 'Exercise List',
        allowCrossTenantAccess: true,
      },
      exactMatch: true,
    },
    addExercise: {
      id: 'add-exercise',
      title: 'Add Exercise',
      type: 'item',
      url: '/training-exercises/add',
      payload: {
        breadcrumb: 'Add Exercise',
        allowCrossTenantAccess: true,
        canSwitchContextLevel: false,
      },
      exactMatch: true,
    },
    editExercise: {
      id: 'edit-exercise',
      title: 'Edit Exercise',
      type: 'item',
      url: '/training-exercises/{id}',
      payload: {
        breadcrumb: 'Edit Exercise',
        allowCrossTenantAccess: true,
        canSwitchContextLevel: false,
      },
      getUrl(id: string): string {
        if (!id) {
          throw Error('id is null or undefined');
        }
        return NavigationTree.settings.editExercise.url.replace('{id}', id);
      },
      exactMatch: true,
    },
  },
  errors: {
    serverIsUnavailable: {
      id: 'serverIsUnavailable',
      title: 'Server is unavailable',
      type: 'item',
      url: '/errors/server-is-unavailable',
      hidden: true,
    },
    notAuthorized: {
      id: 'notAuthorized',
      title: 'Not Authorized',
      type: 'item',
      url: '/errors/error-401',
      hidden: true,
    },
    ipRestriction: {
      id: 'ipRestriction',
      title: 'Ip Restriction',
      type: 'item',
      url: '/errors/ip-restriction',
      hidden: true,
    },
    permissionsRestriction: {
      id: 'permissionsRestriction',
      title: 'Permissions Restriction',
      type: 'item',
      url: '/errors/permissions-restriction',
      hidden: true,
    },
  },
  sign_out: {
    id: 'sign_out',
    title: 'Sign out',
    type: 'item',
    url: undefined,
    isActionable: true,
  },
  in_take: {
    id: 'in_take',
    title: 'In Take',
    type: 'item',
    url: 'in-take',
    hidden: true,
  },
  login: {
    id: 'login',
    title: 'Login',
    type: 'item',
    url: '/login',
    hidden: true,
  },
  twoFactorCode: {
    id: 'two-factor-code',
    title: 'Code Entry',
    type: 'item',
    url: '/2fa',
    hidden: true,
  },
};

export const navigationConfig: INavigationConfig = {
  root: NavigationTree.root,
  dashboards: NavigationTree.dashboards,
  assessments: {
    ...NavigationTree.assessments.assessments,
    children: [
      NavigationTree.assessments.allAssessments,
      NavigationTree.assessments.assessmentsByPerson,
      NavigationTree.assessments.assessmentsByDate,
      NavigationTree.assessments.addAssessment,
    ],
  },
  personalRecords: {
    ...NavigationTree.personalRecords.personalRecords,
    children: [
      NavigationTree.personalRecords.personalRecordsList,
      NavigationTree.personalRecords.insurance,
      NavigationTree.personalRecords.addPersonalRecord,
    ],
  },
  trainings: {
    ...NavigationTree.trainings.trainings,
    children: [
      {
        ...NavigationTree.trainings.inStudio,
        children: [NavigationTree.trainings.locationSchedule],
      },
      {
        ...NavigationTree.trainings.outOfStudio,
        children: [
          NavigationTree.trainings.trainingProgress,
          NavigationTree.trainings.instructors,
        ],
      },
      NavigationTree.trainings.exerciseProgramList,
    ],
  },
  editAssessment: NavigationTree.assessments.editAssessment,
  assessmentSummary: NavigationTree.assessments.assessmentSummary,
  assessmentsDetail: NavigationTree.assessments.assessmentsDetail,
  permissions: {
    ...NavigationTree.permissions.permissions,
    children: [
      NavigationTree.permissions.roles,
      NavigationTree.permissions.users,
      NavigationTree.permissions.ipRestrictions,
      NavigationTree.permissions.preferences,
    ],
  },
  settings: {
    ...NavigationTree.settings.settings,
    children: [
      {
        ...NavigationTree.settings.medicalProviders,
        children: [
          NavigationTree.settings.healthcareProviders,
          NavigationTree.settings.addProvider,
          NavigationTree.settings.editProvider,
        ],
      },
      {
        ...NavigationTree.settings.training,
        children: [NavigationTree.settings.exerciseList],
      },
    ],
  },
  notAuthorized: NavigationTree.errors.notAuthorized,
  sign_out: NavigationTree.sign_out,
  in_take: NavigationTree.in_take,
};

export const navigation: FuseNavigation[] = [
  navigationConfig.dashboards,
  navigationConfig.assessments,
  navigationConfig.trainings,
  navigationConfig.personalRecords,
  navigationConfig.permissions,
  navigationConfig.settings,
  navigationConfig.sign_out,
];
